import React from "react";
import SalesComponent from "./SalesComponent";

const SalesAssistant = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Sales", href: "/sales" },
    { label: "Sales Assistants", href: `/sales/sales-assistant` },
  ];
  return (
    <div>
      <SalesComponent
        breadcrumbPaths={breadcrumbPaths}
        selectedChatIdAPI={"/sales/sales_assistant"}
        chatHistoryAPI={"/sales/sales_assistant/list"}
        selectedChatId={"/sales/sales_assistant"}
        newChat={"sales/sales_assistant/new_chat"}
        sendMessagee={"/sales/sales_assistant/chat"}
        title={"Assistant"}
      />
    </div>
  );
};

export default SalesAssistant;
