import React from 'react'
import Idea from '../IdeaGenerator/Idea'

const ContentCalendar = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Marketing", href: "/marketing" },
    { label: "Social Media Content Calendar", href: `` },
  ];
  return (
    <div>
      <Idea 
      breadcrumbPaths={breadcrumbPaths}
      chatHistoryAPI={"/marketing/social_media_content_calendar/list"}
      selectedChatIdAPI={"/marketing/social_media_content_calendar"}
      selectedChatId={"/marketing/social_media_content_calendar/:chat_id"}
      newChat={"/marketing/social_media_content_calendar/new_chat"}
      sendMessagee={"/marketing/social_media_content_calendar/chat"}
      title={"Social Media Content Calendar"}
       />
    </div>
  )
}

export default ContentCalendar