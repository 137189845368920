import React from 'react'
import Idea from '../IdeaGenerator/Idea';

const IdeaChallenger = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Strategy", href: "/strategy" },
    { label: "Idea Challenger", href: `/strategy/idea-challenger` },
  ];

  return (
    <div>
        <Idea 
      breadcrumbPaths={breadcrumbPaths}
      selectedChatIdAPI={"/strategy/idea_challenger"}
      chatHistoryAPI={"/strategy/idea_challenger/list"}
      selectedChatId={"/strategy/idea-challenger"}
      newChat={"/strategy/idea_challenger/new_chat"}
      sendMessagee={"/strategy/idea_challenger/chat"}
      title="Challenger"
       />
    </div>
  )
}

export default IdeaChallenger