import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  Image,
  Spinner,
  Text,
  Link,
  Heading,
  VStack,
  HStack,
  IconButton,
  Collapse,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Play, Pause, Volume2, VolumeX, Globe } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import useApiClient from "../services/useApiClient";
import { useWebSocket } from "../WebSocketContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { css } from "@emotion/react";
import { motion as Motion } from "framer-motion";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from "react-icons/fa";

// Import your existing assets
import send_active_icon from "../assets/send_active_icon.png";
import send_inactive_icon from "../assets/search_button.png";
import roundedLogo from "../assets/genie_logo_full.png";
import userProfile from "../assets/You.png";
import plus_icon from "../assets/plus_icon.png";
import ideagenerator from "../assets/idea-generator.svg";
import ideaenhancer from "../assets/idea-enhancer.svg";
import ideachallenger from "../assets/idea-challenger.svg";

const coachTypes = {
  "master-coach-audio": {
    api: "master_coach",
    name: "Master Coach",
    route: "venture-box/master-coach-audio",
  },
  "master-coach": {
    api: "master_coach",
    name: "Master Coach",
    route: "venture-box/master-coach",
  },
  "sales-coach": {
    api: "sales_coach",
    name: "Sales Coach",
    route: "sales/sales-coach",
  },
  "hr-coach": { api: "hr_coach", name: "HR Coach", route: "hr/hr-coach" },
  "marketing-coach": {
    api: "marketing_coach",
    name: "Marketing Coach",
    route: "marketing/marketing-coach",
  },
  "strategy-coach": {
    api: "strategy_coach",
    name: "Strategy Coach",
    route: "strategy/strategy-coach",
  },
};

const Conversation_Audio = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const coachType = location.pathname.split("/")[2];
  const chatId = location.pathname.split("/")[3];
  const ws = useWebSocket();
  const message = location.state?.query;
  const messagesEndRef = useRef(null);
  const { api, getUserId } = useApiClient();
  const toast = useToast();
  const audioRefs = useRef({});

  // State variables
  const [chats, setChats] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(1);

  // New state variables for filters and audio
  const [style, setStyle] = useState("polite");
  const [duration, setDuration] = useState("1");
  const [format, setFormat] = useState("audio");
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const fetchChatHistory = async (chatId) => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `/${coachTypes[coachType].api}/chats/${chatId}`
      );
      if (data && data.chatHistory) {
        const formattedHistory = data.chatHistory.map((item) => ({
          type: item.type,
          content: item.content,
          audioUrl: item.audioUrl,
        }));
        setMessageHistory(formattedHistory);
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
      toast({
        title: "Error",
        description: "Failed to fetch chat history",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chatId) {
      //   fetchChatHistory(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setMessageHistory((prev) => [...prev, ...data.responses]);
      };
    }
  }, [ws]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageHistory]);

  const toggleAudio = (messageId) => {
    const audio = audioRefs.current[messageId];
    if (!audio) return;

    if (currentlyPlaying && currentlyPlaying !== messageId) {
      audioRefs.current[currentlyPlaying].pause();
    }

    if (audio.paused) {
      audio.play();
      setCurrentlyPlaying(messageId);
    } else {
      audio.pause();
      setCurrentlyPlaying(null);
    }
  };

  const toggleGlobalAudio = () => {
    setAudioEnabled(!audioEnabled);
    if (currentlyPlaying) {
      audioRefs.current[currentlyPlaying].pause();
      setCurrentlyPlaying(null);
    }
  };

  const sendMessage = async (query) => {
    const message = query || inputValue.trim();
    if (!message) return;

    setInputValue("");
    setIsLoading(true);

    if (!chatId) {
      try {
        const response = await api.post(
          `/${coachTypes[coachType].api}/new_chat`,
          {
            question: message,
          }
        );
        const newChatId = response.data.chat_id;
        sendChatMessage(newChatId, message);
        navigate(`/${coachTypes[coachType].route}/${newChatId}`);
      } catch (error) {
        console.error("Error creating new chat:", error);
        toast({
          title: "Error",
          description: "Failed to create new chat",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      sendChatMessage(chatId, message);
    }
  };

  const sendChatMessage = async (currentChatId, message) => {
    try {
      await api.post(`/${coachTypes[coachType].api}/chat_with_format`, {
        message,
        chat_id: currentChatId,
        duration: parseInt(duration),
        style: style.toLowerCase(),
        format,
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error",
        description: "Failed to send message",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setMessageHistory((prev) => [
        ...prev,
        {
          type: "Error",
          content: "An error occurred while fetching the response.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex direction="row" bg="white" minH="100vh">
      <Box
        bg="#ffffff"
        color="#000000"
        px={8}
        pb={7}
        width="100%"
        position="relative"
        maxW="800px"
        mx="auto"
      >
        {/* Settings Bar */}
        <Flex
          justify="space-between"
          align="center"
          py={4}
          px={2}
          bg="white"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <HStack spacing={4}>
            <IconButton
              icon={audioEnabled ? <FaVolumeUp /> : <FaVolumeMute />}
              onClick={toggleGlobalAudio}
              aria-label="Toggle audio"
              colorScheme={audioEnabled ? "blue" : "gray"}
              variant="outline"
            />
            <Select
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              size="sm"
              w="120px"
              borderColor="gray.300"
            >
              <option value="casual">Casual</option>
              <option value="polite">Polite</option>
              <option value="formal">Formal</option>
            </Select>
            <Select
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              size="sm"
              w="120px"
              borderColor="gray.300"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <option key={num} value={num.toString()}>
                  {num} min
                </option>
              ))}
            </Select>
            <Select
              value={format}
              onChange={(e) => setFormat(e.target.value)}
              size="sm"
              w="120px"
              borderColor="gray.300"
            >
              <option value="audio">Audio</option>
              <option value="text">Text</option>
            </Select>
          </HStack>
        </Flex>

        {/* Messages Container */}
        <Box overflowY="scroll" height="80vh" className="hideScrollbar">
          {messageHistory &&
            messageHistory.length > 0 &&
            messageHistory.map((message, index) => (
              <MessageHandler
                key={index}
                message={message}
                sendMessage={sendMessage}
                audioRefs={audioRefs}
                currentlyPlaying={currentlyPlaying}
                toggleAudio={toggleAudio}
                audioEnabled={audioEnabled}
                messageIndex={index}
              />
            ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Box>

        {/* Input Box */}
        <Box
          mt={8}
          width="100%"
          position="sticky"
          backgroundColor="white"
          bottom="3em"
        >
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h="80px"
            align="center"
            justify="center"
          >
            <Input
              placeholder="Type your response here..."
              size="lg"
              bg="white"
              pr="4.5rem"
              border="none"
              value={inputValue}
              h="60px"
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              focusBorderColor="transparent"
              _focus={{ boxShadow: "none" }}
            />
            <Image
              w="40px"
              h="40px"
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_button"
              onClick={sendMessage}
              cursor="pointer"
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

const AudioPlayer = ({
  audioUrl,
  selectedLanguage,
  onLanguageChange,
  content,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);

  const languages = {
    english: "English",
    hindi: "हिंदी",
    gujarati: "ગુજરાતી",
    tamil: "தமிழ்",
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", updateProgress);
      audioRef.current.addEventListener("loadedmetadata", () => {
        setDuration(audioRef.current.duration);
      });
      audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, [audioUrl]);

  const updateProgress = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (e) => {
    if (!progressBarRef.current || !audioRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const pos = (e.clientX - rect.left) / rect.width;
    audioRef.current.currentTime = pos * duration;
  };

  return (
    <div className="flex flex-col space-y-4 p-4 text-gray-800">
      {/* Language Selector */}
      {/* <div className="flex items-center space-x-2">
          <Globe className="h-5 w-5 text-gray-600" />
          <select
            value={selectedLanguage}
            onChange={(e) => onLanguageChange(e.target.value)}
            className="text-base border-none bg-transparent focus:outline-none focus:ring-0 text-gray-600"
          >
            {Object.entries(languages).map(([code, name]) => (
              <option key={code} value={code}>{name}</option>
            ))}
          </select>
        </div> */}

      {/* Content Display */}
      <div className="text-base whitespace-pre-wrap">{content}</div>

      {/* Audio Controls */}
      <div className="flex flex-col space-y-2">
        <div className="text-sm font-medium" style={{ marginBottom: "10px" }}>
          Audio Response
        </div>

        <Flex>
          <button
            onClick={togglePlayPause}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            {isPlaying ? (
              <Pause className="h-6 w-6" />
            ) : (
              <Play className="h-6 w-6" />
            )}
          </button>
          {/* <div className="flex-1 relative h-1 bg-gray-200 rounded">
              <div
                ref={progressBarRef}
                onClick={handleProgressClick}
                className="absolute inset-0 cursor-pointer"
              >
                <div
                  className="h-full bg-gray-600 rounded"
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                />
              </div>
            </div> */}
          <div className="text-sm text-gray-600 min-w-[80px] text-right">
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
        </Flex>
      </div>

      <audio
        ref={audioRef}
        src={audioUrl}
        preload="metadata"
        className="hidden"
      />
    </div>
  );
};

const MessageHandler = ({
  message,
  sendMessage,
  audioRefs,
  currentlyPlaying,
  toggleAudio,
  audioEnabled,
  messageIndex,
}) => {
  const COMPONENT_MAP = {
    Query: (props) => <Query {...props} />,
    GPT: (props) => (
      <GPT
        {...props}
        audioRefs={audioRefs}
        currentlyPlaying={currentlyPlaying}
        toggleAudio={toggleAudio}
        audioEnabled={audioEnabled}
        messageIndex={messageIndex}
      />
    ),
    GPT_AUDIO: (props) => (
      <GPT
        {...props}
        audioRefs={audioRefs}
        currentlyPlaying={currentlyPlaying}
        toggleAudio={toggleAudio}
        audioEnabled={audioEnabled}
        messageIndex={messageIndex}
      />
    ),
    GPT_AUDIO_MULTILINGUAL: (props) => <GPT {...props} />,
    Sources,
    FollowUp,
  };

  const Component = COMPONENT_MAP[message.type];
  return Component ? (
    <Component
      content={message.content}
      sendMessage={sendMessage}
      audioUrl={message.audioUrl}
    />
  ) : null;
};

const GPT = ({ content, audioUrl, messageIndex }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const audioRef = useRef(null);

  // Get the backend URL from environment variable or default to localhost
  const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:3001";

  // Language options with display names
  const languages = {
    english: "English",
    hindi: "हिंदी",
    gujarati: "ગુજરાતી",
    // kannada: 'ಕನ್ನಡ',
    tamil: "தமிழ்",
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => setIsPlaying(false);
      audioRef.current.onerror = () => setError(true);
    }
  }, []);

  useEffect(() => {
    // Stop playing when language changes
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    }
    setError(false);
  }, [selectedLanguage]);

  const togglePlayback = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      // Stop all other playing audio elements first
      document.querySelectorAll("audio").forEach((audio) => {
        if (audio !== audioRef.current) {
          audio.pause();
          audio.currentTime = 0;
        }
      });

      // Play this audio
      audioRef.current.play().catch((err) => {
        console.error("Error playing audio:", err);
        setError(true);
      });
    }
    setIsPlaying(!isPlaying);
  };

  // Handle multilingual audio URLs
  const getAudioUrl = () => {
    if (!audioUrl || !audioUrl[selectedLanguage]) return null;
    return `${BACKEND_URL}${audioUrl[selectedLanguage].audioUrl}`;
  };

  // Get text content for selected language
  const getContent = () => {
    if (audioUrl && audioUrl[selectedLanguage]) {
      return audioUrl[selectedLanguage].text;
    }
    return content;
  };

  return (
    <div className="flex flex-col space-y-4 p-4 bg-white rounded-lg shadow-sm">
      <Flex>
        <div
          className="flex items-center space-x-2"
          style={{ marginRight: "2em" }}
        >
          <Globe
            style={{ marginBottom: "10px" }}
            className="h-4 w-4 text-gray-500"
          />
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="text-sm border rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {Object.entries(languages).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>

        {audioUrl && (
          <AudioPlayer
            audioUrl={getAudioUrl()}
            isPlaying={isPlaying}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        )}

        {/* {audioUrl && (
          <div className="flex items-center space-x-2">
            <audio
              ref={audioRef}
              src={getAudioUrl()}
              preload="metadata"
              onError={() => setError(true)}
            />
            <button
              onClick={togglePlayback}
              disabled={error}
              className={`flex items-center space-x-1 px-3 py-1 rounded-full text-sm transition-colors ${
                error
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
              }`}
            >
              {isPlaying ? (
                <>
                  <Pause className="h-4 w-4" />
                  <span>Pause</span>
                </>
              ) : (
                <>
                  <Play className="h-4 w-4" />
                  <span>Play</span>
                </>
              )}
            </button>
          </div>
        )} */}
      </Flex>

      <br />
      <Text>Answer:</Text>

      <div className="prose max-w-none">
        <div className="text-gray-800 whitespace-pre-wrap">{getContent()}</div>
      </div>

      {error && (
        <div className="text-sm text-red-500 flex items-center space-x-1">
          <Volume2 className="h-4 w-4" />
          <span>Failed to load audio</span>
        </div>
      )}
    </div>
  );
};

const Query = ({ content }) => (
  <Box
    bg="#FFFFFF"
    mt={4}
    display={"flex"}
    flexDirection={"row"}
    justifyContent={"flex-start"}
    alignItems={"center"}
    gap={"16px"}
  >
    <Image
      src={userProfile}
      // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyTh5ljvubR6s3LeERqK8DHldWwD3DcwBLw&s"
      // border={"2px solid #000"}
      // borderRadius={"40px"}
      width={"30px"}
      p={0}
      height={"30px"}
    />
    <Flex direction={"column"} gap={"5px"}>
      <Text fontSize="12px" color={"#333333"}>
        You
      </Text>
      <Text fontSize="lg" color={"#333333"} fontWeight="semibold">
        {content ? content : "Not able to fetch question"}
      </Text>
    </Flex>
  </Box>
);

const Sources = ({ content }) => (
  <Box mb={{ base: 0, sm: 8 }} mt={4} bg="#FFFFFF">
    <Heading
      as="h4"
      size="md"
      fontWeight={400}
      color={"##333333"}
      mb={1}
      mt={1}
    >
      Sources:
    </Heading>
    <Flex
      spacing={4}
      gap={2}
      overflowX={"scroll"}
      css={css`
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      `}
    >
      {content?.map((source, index) => (
        <Link key={index} href={source.link} isExternal>
          <Box
            p={2}
            bg="#FEF4E9"
            color="#000000"
            minW={"221px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            borderRadius="md"
            minH={"56px"}
            mt={2}
          >
            <Text
              color={"#333333"}
              fontSize={"12px"}
              lineHeight={"18px"}
              dangerouslySetInnerHTML={{ __html: source.title }} // Renders HTML tags like <b>
            />
            <Text color={"#C01F27"} fontSize="10px">
              {new URL(source.link).hostname}
            </Text>
          </Box>
        </Link>
      ))}
    </Flex>
  </Box>
);

const FollowUp = ({ content, sendMessage }) => {
  const [followUp, setFollowUp] = useState([]);

  // console.log("FollowUp content: ", typeof content, content);

  useEffect(() => {
    try {
      // const parsedContent = JSON.parse(content);
      console.log("Parsed content: ", content);
      if (content.follow_up) {
        setFollowUp(content.follow_up);
        console.log("Parsed follow_up: ", content.follow_up);
      } else {
        console.log("No follow_up field in content.");
      }
    } catch (error) {
      console.error("Error parsing follow-up content:", error);
    }
  }, [content]);

  const handleFollowUpClick = (text, e) => {
    e.preventDefault();
    sendMessage(text);
    console.log(text);
  };

  if (followUp.length > 0)
    return (
      <Box>
        <Box my={4} bg="#FFFFFF">
          <Heading
            fontSize="16px"
            lineHeight={"24px"}
            pb={"12px"}
            color={"#F68821"}
            fontWeight={400}
          >
            Related questions:
          </Heading>
          {followUp.map((text, index) => (
            <Link
              key={index}
              href="#"
              onClick={(e) => handleFollowUpClick(text, e)}
              display="block"
              p={"8px 0px"}
              borderTop={"1px solid #FEE6D4"}
            >
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text p={"6px 0px"} fontSize={"14px"}>
                  {text}
                </Text>
                <Image src={plus_icon} h={"30px"} alt="" />
              </Flex>
            </Link>
          ))}
        </Box>
        <Box my={4} bg="#FFFFFF">
          <Heading
            fontSize="16px"
            lineHeight={"24px"}
            pb={"12px"}
            color={"#F68821"}
            fontWeight={400}
          >
            AI Assitants
          </Heading>
          <Box display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
            <Link href="/strategy/idea-generator" maxW={"240px"}>
              <Image src={ideagenerator} maxW={"240px"} alt="ideagenerator" />
            </Link>
            <Link href="/strategy/idea-enhancer" maxW={"240px"}>
              <Image src={ideaenhancer} maxW={"240px"} alt="ideaenhancer" />
            </Link>
            <Link href="/strategy/idea-challenger" maxW={"240px"}>
              <Image src={ideachallenger} maxW={"240px"} alt="ideachallenger" />
            </Link>
          </Box>
          {/* <Image src={aiassitants} alt="aiassitants"/> */}
        </Box>
        {/* <Footer/> */}
      </Box>
    );
  else return <div></div>;
};

// Keep your existing Query, Sources, and FollowUp components unchanged
export default Conversation_Audio;
