import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  Image,
  Spinner,
  Text,
  Link,
  Heading,
  VStack,
  HStack,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import useApiClient from "../services/useApiClient";
import { useWebSocket } from "../WebSocketContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { css } from "@emotion/react";
import { motion as Motion } from "framer-motion";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import send_active_icon from "../assets/send_active_icon.png";
import send_inactive_icon from "../assets/search_button.png";
import home_icon from "../assets/home_icon.png";
import start_new from "../assets/start_new.png";
import roundedLogo from "../assets/genie_logo_full.png";
import aiassitants from "../assets/ai-assitants.svg";
import ideagenerator from "../assets/idea-generator.svg";
import ideaenhancer from "../assets/idea-enhancer.svg";
import ideachallenger from "../assets/idea-challenger.svg";
import plus_icon from "../assets/plus_icon.png";
import { shortenText } from "../services/common";
import userProfile from "../assets/You.png";
import Footer from "./Footer/Footer";


const coachTypes = {
  "master-coach": {
    api: "master_coach",
    name: "Master Coach",
    route: "venture-box/master-coach",
  },
  "sales-coach": {
    api: "sales_coach",
    name: "Sales Coach",
    route: "sales/sales-coach",
  },
  "hr-coach": { api: "hr_coach", name: "HR Coach", route: "hr/hr-coach" },
  "marketing-coach": {
    api: "marketing_coach",
    name: "Marketing Coach",
    route: "marketing/marketing-coach",
  },
  "strategy-coach": {
    api: "strategy_coach",
    name: "Strategy Coach",
    route: "strategy/strategy-coach",
  },
};

const Conversation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const coachType = location.pathname.split("/")[2];
  // console.log("coachType: ", coachType);
  const chatId = location.pathname.split("/")[3];
  const ws = useWebSocket();
  const message = location.state?.query;
  const messagesEndRef = useRef(null);
  const { api, getUserId } = useApiClient();
  const [chats, setChats] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(1);

  // WebSocket connection setup

  const fetchChatHistory = async (chatId) => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `/${coachTypes[coachType].api}/chats/${chatId}`
      );
      if (data && data.chatHistory) {
        const formattedHistory = data.chatHistory.map((item) => ({
          type: item.type,
          content: item.content,
        }));
        setMessageHistory(formattedHistory);
        setIsLoading(false);
        // scrollToBottom();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching chat history:", error);
    }
  };

  useEffect(() => {
    if (chatId) {
      fetchChatHistory(chatId);
    }
    console.log("websocketurl::::::: ", process.env.REACT_APP_WEBSOCKET_URL);
  }, [chatId]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("data: ", data);
        setMessageHistory((prev) => [...prev, ...data.responses]);
      };
    }
  }, [ws]);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageHistory]);

  const sendMessage = async (query) => {
    const message = query || inputValue.trim();
    if (!message) return;

    setInputValue("");
    setIsLoading(true);

    if (!chatId) {
      try {
        const response = await api.post(
          `/${coachTypes[coachType].api}/new_chat`,
          {
            question: message,
          }
        );
        const newChatId = response.data.chat_id;
        // sendMessageForCoach({chat_id: chatId,
        //     coach_type: coachType,
        //     message: message,});
        sendChatMessage(newChatId, message);
        navigate(`/${coachTypes[coachType].route}/${newChatId}`);
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    } else {
      // sendMessageForCoach({chat_id: chatId,
      //         coach_type: coachType,
      //         message: message,});
      sendChatMessage(chatId, message);
    }
  };

  const sendChatMessage = async (currentChatId, message) => {
    try {
      await api.post(`/${coachTypes[coachType].api}/chat`, {
        message,
        chat_id: currentChatId,
      });

      //   if (ws && ws.readyState === WebSocket.OPEN) {
      //     ws.send(JSON.stringify({ chat_id: currentChatId, message }));
      //   }
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
      setMessageHistory((prev) => [
        ...prev,
        {
          type: "Error",
          content: "An error occurred while fetching the response.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleSelectChat = (chatId) => {
    navigate(`/${coachTypes[coachType].route}/${chatId}`);
  };

  return (
    <Flex direction="row" bg="white" minH="100vh">
      <Box
        bg="#ffffff"
        color="#000000"
        px={8}
        pb={7}
        width="100%"
        position={"relative"}
        maxW="800px"
        mx="auto"
      >
        <Box overflowY={"scroll"} height={"80vh"} className="hideScrollbar">
          {messageHistory &&
            messageHistory.length > 0 &&
            messageHistory.map((message, index) => (
              <MessageHandler
                key={index}
                message={message}
                sendMessage={sendMessage}
              />
            ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Box>
        <Box mt={8} width="100%" position={"sticky"} backgroundColor={"white"} bottom={"3em"}>
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h="80px"
            align="center"
            justify="center"
          >
            <Input
              placeholder="Type your response here..."
              size="lg"
              bg="white"
              pr="4.5rem"
              border="none"
              value={inputValue}
              h="60px"
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              focusBorderColor="transparent"
              _focus={{ boxShadow: "none" }}
            />
            <Image
              w="40px"
              h="40px"
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_button"
              onClick={sendMessage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

const MessageHandler = ({ message, sendMessage }) => {
  const COMPONENT_MAP = {
    Query,
    GPT,
    Sources,
    FollowUp,
  };

  const Component = COMPONENT_MAP[message.type];

  return Component ? (
    <Component content={message.content} sendMessage={sendMessage} />
  ) : null;
};

const GPT = ({ content }) => (
  <Flex
    direction={"row"}
    display={"flex"}
    align="start"
    justifyContent={"start"}
    gap={"12px"}
    py={"14px"}
    spacing={3}
  >
    <Image src={roundedLogo} width={"30px"} />
    <Flex direction={"column"} gap={"5px"}>
      <Text
        fontWeight="normal"
        display={"flex"}
        alignItems={"center"}
        gap={"10px"}
        justifyContent={"flex-start"}
        fontSize="12px"
        color={"#C01F27"}
      >
      Venture Box AI
      </Text>

      <Motion.Box
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        ml={"45px"}
      >
        <ReactMarkdown
          className="prose contentBot w-full break-words prose-p:leading-relaxed"
          remarkPlugins={[remarkGfm]}
          components={{
            a: ({ node, ...props }) => (
              <a
                {...props}
                style={{
                  color: "blue",
                  fontWeight: "normal",
                  marginTop: "8px",
                  marginLeft: "25px",
                }}
                className=""
              />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </Motion.Box>
    </Flex>
  </Flex>
);

const Query = ({ content }) => (
  <Box
    bg="#FFFFFF"
    mt={4}
    display={"flex"}
    flexDirection={"row"}
    justifyContent={"flex-start"}
    alignItems={"center"}
    gap={"16px"}
  >
    <Image
      src={userProfile}
      // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyTh5ljvubR6s3LeERqK8DHldWwD3DcwBLw&s"
      // border={"2px solid #000"}
      // borderRadius={"40px"}
      width={"30px"}
      p={0}
      height={"30px"}
    />
    <Flex direction={"column"} gap={"5px"}>
      <Text fontSize="12px" color={"#333333"}>
        You
      </Text>
      <Text fontSize="lg" color={"#333333"} fontWeight="semibold">
        {content ? content : "Not able to fetch question"}
      </Text>
    </Flex>
  </Box>
);

const Sources = ({ content }) => (
  <Box mb={{ base: 0, sm: 8 }} mt={4} bg="#FFFFFF">
    <Heading
      as="h4"
      size="md"
      fontWeight={400}
      color={"##333333"}
      mb={1}
      mt={1}
    >
      Sources:
    </Heading>
    <Flex
      spacing={4}
      gap={2}
      overflowX={"scroll"}
      css={css`
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      `}
    >
      {content?.map((source, index) => (
        <Link key={index} href={source.link} isExternal>
          <Box
            p={2}
            bg="#FEF4E9"
            color="#000000"
            minW={"221px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            borderRadius="md"
            minH={"56px"}
            mt={2}
          >
            <Text
              color={"#333333"}
              fontSize={"12px"}
              lineHeight={"18px"}
              dangerouslySetInnerHTML={{ __html: source.title }} // Renders HTML tags like <b>
            />
            <Text color={"#C01F27"} fontSize="10px">
              {new URL(source.link).hostname}
            </Text>
          </Box>
        </Link>
      ))}
    </Flex>
  </Box>
);

const FollowUp = ({ content, sendMessage }) => {
  const [followUp, setFollowUp] = useState([]);

  // console.log("FollowUp content: ", typeof content, content);

  useEffect(() => {
    try {
      // const parsedContent = JSON.parse(content);
      console.log("Parsed content: ", content);
      if (content.follow_up) {
        setFollowUp(content.follow_up);
        console.log("Parsed follow_up: ", content.follow_up);
      } else {
        console.log("No follow_up field in content.");
      }
    } catch (error) {
      console.error("Error parsing follow-up content:", error);
    }
  }, [content]);

  const handleFollowUpClick = (text, e) => {
    e.preventDefault();
    sendMessage(text);
    console.log(text);
  };

  if (followUp.length > 0)
    return (
      <Box>
        <Box my={4} bg="#FFFFFF">
          <Heading
            fontSize="16px"
            lineHeight={"24px"}
            pb={"12px"}
            color={"#F68821"}
            fontWeight={400}
          >
            Related questions:
          </Heading>
          {followUp.map((text, index) => (
            <Link
              key={index}
              href="#"
              onClick={(e) => handleFollowUpClick(text, e)}
              display="block"
              p={"8px 0px"}
              borderTop={"1px solid #FEE6D4"}
            >
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text p={"6px 0px"} fontSize={"14px"}>
                  {text}
                </Text>
                <Image src={plus_icon} h={"30px"} alt="" />
              </Flex>
            </Link>
          ))}
        </Box>
        <Box my={4} bg="#FFFFFF">
          <Heading
            fontSize="16px"
            lineHeight={"24px"}
            pb={"12px"}
            color={"#F68821"}
            fontWeight={400}
          >
            AI Assitants
          </Heading>
          <Box display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
            <Link href="/strategy/idea-generator" maxW={"240px"}>
              <Image src={ideagenerator} maxW={"240px"} alt="ideagenerator" />
            </Link>
            <Link href="/strategy/idea-enhancer" maxW={"240px"}>
              <Image src={ideaenhancer} maxW={"240px"} alt="ideaenhancer" />
            </Link>
            <Link href="/strategy/idea-challenger" maxW={"240px"}>
              <Image src={ideachallenger} maxW={"240px"} alt="ideachallenger" />
            </Link>
          </Box>
          {/* <Image src={aiassitants} alt="aiassitants"/> */}
        </Box>
        {/* <Footer/> */}
      </Box>
    );
  else return <div></div>;
};

export default Conversation;
