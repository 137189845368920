import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Text,
  Button,
  useToast,
  HStack,
  CloseButton,
  createIcon,
} from '@chakra-ui/react';
// import { ThumbUpIcon, ThumbDownIcon } from '@chakra-ui/icons';

const FeedbackOption = ({ option, isSelected, onClick }) => (
  <Box
    as="button"
    px={4}
    py={2}
    borderRadius="full"
    bg={isSelected ? "#ff8400" : "gray.100"}
    color={isSelected ? "white" : "black"}
    _hover={{ bg: isSelected ? "#ff8400" : "gray.200" }}
    onClick={onClick}
    transition="all 0.2s"
  >
    <Text fontSize="12px">{option}</Text>
  </Box>
);


// Custom ThumbUpIcon
const ThumbUpIcon = createIcon({
    displayName: "ThumbUpIcon",
    viewBox: "0 0 24 24",
    path: (
      <path
        fill="currentColor"
        d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"
      />
    ),
  });
  
  // Custom ThumbDownIcon
  const ThumbDownIcon = createIcon({
    displayName: "ThumbDownIcon",
    viewBox: "0 0 24 24",
    path: (
      <path
        fill="currentColor"
        d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4H6.56c-.71 0-1.37.37-1.73.97L2.17 11.12z"
      />
    ),

});
const FeedbackComponent = ({ messageId, chatId, onSubmitFeedback }) => {
    const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [feedbackType, setFeedbackType] = useState(null);
    const toast = useToast();
  
    const positiveFeedbackOptions = [
      "Very helpful",
      "Well explained",
      "Accurate information",
    ];
  
    const negativeFeedbackOptions = [
      "Shouldn't have used Memory",
      "Don't like the style",
      "Not factually correct",
    ];
  
    const handleImpression = (impression) => {
      setFeedbackType(impression ? 'positive' : 'negative');
      setShowFeedbackOptions(true);
      setSelectedReasons([]);
    };
  
    const handleReasonToggle = (reason) => {
      setSelectedReasons(prev => 
        prev.includes(reason)
          ? prev.filter(r => r !== reason)
          : [...prev, reason]
      );
    };
  
    const handleCloseFeedback = () => {
      setShowFeedbackOptions(false);
      setSelectedReasons([]);
    };
  
    const submitDetailedFeedback = async () => {
      try {
        await onSubmitFeedback({
          chat_id: chatId,
          message_id: messageId,
          isLiked: feedbackType === 'positive',
          reasons: selectedReasons,
        });
  
        toast({
          title: "Feedback submitted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        handleCloseFeedback();
      } catch (error) {
        console.error("Error submitting detailed feedback:", error);
        toast({
          title: "Failed to submit feedback",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Box>
        <Flex justifyContent="flex-end" mt={2}>
          <IconButton
            icon={<ThumbUpIcon />}
            aria-label="Thumbs up"
            size="sm"
            mr={2}
            onClick={() => handleImpression(true)}
            colorScheme={feedbackType === 'positive' ? "green" : "gray"}
          />
          <IconButton
            icon={<ThumbDownIcon />}
            aria-label="Thumbs down"
            size="sm"
            onClick={() => handleImpression(false)}
            colorScheme={feedbackType === 'negative' ? "red" : "gray"}
          />
        </Flex>
  
        {showFeedbackOptions && (
          <Box
            bg="white"
            p={4}
            mt={4}
            borderRadius="md"
            border="1px solid #e5e5e5"
            zIndex={1000}
            position="relative"
          >
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={handleCloseFeedback}
            />
            <Text fontWeight="semibold" fontSize="15px" mb={2}>
              Tell us more:
            </Text>
            <Flex flexWrap="wrap" gap={2} justify="start">
              {(feedbackType === 'positive' ? positiveFeedbackOptions : negativeFeedbackOptions).map((option) => (
                <FeedbackOption
                  key={option}
                  option={option}
                  isSelected={selectedReasons.includes(option)}
                  onClick={() => handleReasonToggle(option)}
                />
              ))}
            </Flex>
            <Flex justify="end" mt={4}>
              <Button onClick={submitDetailedFeedback}>Submit Feedback</Button>
            </Flex>
          </Box>
        )}
      </Box>
    );
  };
  
  export default FeedbackComponent;