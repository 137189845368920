import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  Collapse,
  Link,
} from "@chakra-ui/react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";

import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/genie_logo_full.png";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import useApiClient from "../../services/useApiClient";
import userProfile from "../../assets/You.png";
import FeedbackComponent from "../Sales/FeedbackComponent/FeedbackComponent";
import ConversationRating from "../ConversationRating/ConversationRating";
import HeyButton from "../HeyButton/HeyButton";


const Idea = ({
  breadcrumbPaths,
  chatHistoryAPI,
  selectedChatIdAPI,
  newChat,
  sendMessagee,
  title
}) => {
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [showRating, setShowRating] = useState(false);



   // Add state for HeyButton visibility
   const [showHeyButton, setShowHeyButton] = useState(true);

  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${chatHistoryAPI}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        scrollToBottom();
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };
  useEffect(() => {
    fetchChatHistory();
  }, [chatId]);
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getNewChatId = async (question) => {
    try {
      const response = await api.post(`${newChat}`, {
        question,
      });
      if (response) {
        const data = response.data;
        console.log("New chat ID:", data.chat_id);
        setChatId(data.chat_id);
        console.log("Chat ID set:", chatId);
        return data.chat_id;
      } else {
        console.error("Error getting new chat ID:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error getting new chat ID:", error);
      return null;
    }
  };

  const sendMessage = async (message, chatId) => {
    try {
      const response = await api.post(`${sendMessagee}`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        return data;
      } else {
        console.error("Error sending message:", response.statusText);
        return { error: "Failed to send message" };
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return { error: "Failed to send message" };
    }
  };

  const handleRatingSubmission = async (chatId, rating) => {
    try {
      const response = await api.post(`${selectedChatIdAPI}/conversation-rating`, {
        chat_id: chatId,
        conversation_rating: rating
      });
      return response;
    } catch (error) {
      console.error("Error submitting rating:", error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      // Add user message to chat
      setChatMessages((prev) => [
        ...prev,
        { type: "user", content: inputValue },
      ]);
      const userMessage = inputValue;
      setInputValue("");

      // Set loading state
      setIsLoading(true);

      let currentChatId = chatId;

      try {
        if (!currentChatId) {
          currentChatId = await getNewChatId(userMessage);
          setChatId(currentChatId);
          console.log("New chat ID:", currentChatId);
        }

        // Send message and get response
        const response = await sendMessage(userMessage, currentChatId);
        console.log("Response from sendMessage:", response);

        let aiResponse = "No response received";
        if (response && response.response) {
          aiResponse = response.response;
        } else if (response && response.error) {
          aiResponse = `Error: ${response.error}`;
        }

        setChatMessages((prev) => [
          ...prev,
          { type: "ai", content: aiResponse, id: response.aiMessageId },
        ]);
        if ((chatMessages.length + 2) % 10 === 0) {
          setShowRating(true);
        }
      
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setChatMessages((prev) => [
          ...prev,
          {
            type: "ai",
            content: "Sorry, an error occurred while processing your message.",
          },
        ]);
      } finally {
        // Reset loading state
        setIsLoading(false);
      }
    }
  };


  const handleFeedbackSubmission = async (feedbackData) => {
    console.log(selectedChatIdAPI + "/feedback");
        try {
          const response = await api.post(`${selectedChatIdAPI}/feedback`, feedbackData);
          if (response.status === 200) {
            setChatMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.id === feedbackData.message_id ? { ...msg, impression: feedbackData.isLiked } : msg
              )
            );
          } else {
            throw new Error("Failed to submit feedback");
          }
        } catch (error) {
          console.error("Error submitting feedback:", error);
          throw error;
        }
      };
    
  return (
    <Flex minH="100vh" bg="white">

      <Flex
        gap={"12px"}
        height={{ base: "", sm: "100vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 5, sm: 16 }}
      >
        <VStack align="stretch" spacing={4}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
             {title}
          </Text>
        </VStack>

        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
        //   mb={4}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
          px={4} // Add horizontal padding
        >

{showHeyButton && chatMessages.length === 0 && (
            <HeyButton 
              isVisible={showHeyButton && chatMessages.length === 0}
              onChatStart={async () => {
                const currentChatId = await getNewChatId("Hey");
                setChatId(currentChatId);
                
                const response = await sendMessage("Hey", currentChatId);
                
                if (response && response.response) {
                  setChatMessages([
                    {
                      id: Date.now(),
                      type: "user",
                      content: "Hey",
                    },
                    {
                      id: response.messageId || Date.now() + 1,
                      type: "ai",
                      content: response.response,
                    },
                  ]);
                }
                setShowHeyButton(false);
              }}
            />
          )}
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              // bg={message.type === "user" ? "blue.100" : "gray.100"}
              p={2}
              className="hideScrollbar"
              borderRadius="md"
              mb={2}
              maxWidth="70%"
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box AI
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"36px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
              {console.log(message,"from Idea js")}
              {message.type === "ai" && (
            <FeedbackComponent
              messageId={message.id}
              chatId={chatId}
              onSubmitFeedback={handleFeedbackSubmission}
            />
          )}
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
        </Flex>
        <ConversationRating
          isOpen={showRating}
          onClose={() => setShowRating(false)}
          onSubmitRating={handleRatingSubmission}
          chatId={chatId}
        />

{showRating ? "" : (


        <Box
          position="sticky"
          bottom={0}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          mx={"auto"}
          bg="white"
          p={4}
        >
          <Flex
            border={"1px solid #D9D9D9"}
            borderRadius={"16px"}
            h={"70px"}
            align={"center"}
            justifyContent={"center"}
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border={"none"}
              h={"40px"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor={"transparent"}
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
            {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
          </Flex>
        </Box>
      )}
      </Flex>
    </Flex>
  );
};

export default Idea;
