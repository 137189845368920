import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, Input, Image, Spinner, Text, Link, Heading, VStack, HStack,
    IconButton,
    Collapse, } from "@chakra-ui/react";
import { useLocation,useParams } from "react-router-dom";
import useApiClient from "../../services/useApiClient";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { css } from "@emotion/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import home_icon from "../../assets/home_icon.png";
import start_new from "../../assets/start_new.png";
import roundedLogo from "../../assets/roundedLogo.svg";
import plus_icon from "../../assets/plus_icon.png";
import { shortenText } from "../../services/common";
import { motion } from "framer-motion";
import userProfile from "../../assets/You.png";

const SidebarItem = ({ label, children, isOpen, onToggle }) => (
    <Box w="full">
      <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
        <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
        <IconButton
          icon={
            isOpen ? (
              <MinusIcon color={"#C01F27"} />
            ) : (
              <AddIcon color={"#C01F27"} />
            )
          }
          size="sm"
          variant={""}
          aria-label={isOpen ? "Collapse" : "Expand"}
        />
      </HStack>
      <Collapse in={isOpen}>
        <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
          {children}
        </VStack>
      </Collapse>
    </Box>
  );

const HrCoach = () => {
  // const { chatId } = useParams();
  const location = useLocation();
  const chatId = location.pathname.split("/")[2];
  const message = location.state?.inputValue;
  console.log(location,message);
  const messagesEndRef = useRef(null);
  const { api } = useApiClient();
  const [chats, setChats] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchChats();
    if (chatId && message)  {
      sendInitialMessage(message);
    }
    if (chatId) {
     
      fetchChatHistory(chatId);
    }
  }, [chatId, message]);

  const sendInitialMessage = async (initialMessage) => {
    setIsLoading(true);
    try {
      const response = await api.post(`/hr_coach/chat`, {
        message: initialMessage,
        chat_id: chatId,
      });

      if (response.data.responses) {
        const newMessages = [
          { type: "Query", content: initialMessage },
          ...response.data.responses.map((resp) => ({
            type: resp.type,
            content: resp.content,
          })),
        ];

        setMessageHistory(newMessages);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error sending initial message:", error);
      setMessageHistory([
        { type: "Query", content: initialMessage },
        { type: "Error", content: "An error occurred while fetching the response." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChatHistory = async (chatId) => {
    try {
      const { data } = await api.get(`/hr_coach/chats/${chatId}`);
      if (data && data.chatHistory) {
        const formattedHistory = data.chatHistory.map(item => ({
          type: item.payload.type,
          content: item.payload.content
        }));
        setMessageHistory(formattedHistory);
        scrollToBottom();
      }
  
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const fetchChats = async (chatId) => {
    try {
      const { data } = await api.get(`/hr_coach/chats`);
      if (data) {
        // console.log(data);
        setChats(data.chats);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const sendMessage = async () => {
    const message = inputValue.trim();
    if (!message) return;


    setInputValue("");
    setIsLoading(true);

    setMessageHistory((prev) => [...prev, { type: "Query", content: message }]);
  
    scrollToBottom();
 // Show spinner
 setIsLoading(true);
    try {
      const response = await api.post(`/hr_coach/chat`, {
        message,
        chat_id: chatId,
      });

      if (response.data.responses) {
        const newMessages = [
          ...messageHistory,
          { type: "Query", content: message },
          ...response.data.responses.map((resp) => ({
            type: resp.type,
            content: resp.content,
          })),
        ];
  
        // Deduplicate messages
        const uniqueMessages = Array.from(
          new Set(newMessages.map((m) => JSON.stringify(m)))
        ).map((m) => JSON.parse(m));
  
        // Update message history with unique messages
        setMessageHistory(uniqueMessages);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally, add an error message to the chat
      setMessageHistory(prev => [...prev, { type: "Error", content: "An error occurred while fetching the response." }]);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectChat = (chatId) => {
    // setSelectedChatId(chatId);
    // setChatId(chatId);
    console.log(chatId);
    window.location.href = `/hr/hr-coach/${chatId}`;
    // Here you might want to fetch the messages for the selected chat
    // and update the chatMessages state
  };

  // const handleSelectChat = (chatId, message) => {
  //   const navigate = useNavigate();
  
  //   // Navigate to the coach page with chatId and message as state
  //   navigate(`/venture-box/master-coach/${chatId}`, { state: { message } });
  // };
  

  return (
    <Flex direction="row" bg="white" minH="100vh">
         {/* Sidebar */}
      <VStack
        align="start"
        w={{ base: "full", md: "280px" }}
        p={4}
        spacing={0}
        boxShadow="md"
        display={{ base: "none", lg: "block" }}
        bg="#FEF8F5"
      >
        <Link href="/" textDecoration={"none"} mb={2}>
          <Image src={home_icon} alt="Home" />
        </Link>
        <Box w="-webkit-fill-available" border={"1px solid #FEE6D4"}></Box>
        <HStack
          cursor="pointer"
          mt={"1em"}
          borderRadius={"8px"}
          bg={"#FEF1E9"}
          w="-webkit-fill-available"
          justifyContent={"center"}
          onClick={() => {
            setMessageHistory([]);
            window.location.href = "/hr/hr-coach";
          }}
        >
          <Text bg={"#FEF1E9"} color={"#C01F27"} my={3}>
            Start New
          </Text>
          <Image src={start_new} alt="Start New" boxSize="20px" />
        </HStack>

        <SidebarItem
          label="Chat History"
          isOpen={openIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          {chats && chats.length > 0 ? (
            <Flex
              direction="column"
              bg="#FEF8F5"
              w="-webkit-fill-available"
              justifyContent="flex-start"
              height={"400px"}
              overflowY={"scroll"}
              className="hideScrollbar"
            >
              {chats.map((chat, index) => (
                <Box
                  key={index}
                  pl={4}
                  py={2}
                  cursor={"pointer"}
                  onClick={() => handleSelectChat(chat.chat_id)}
                >
                  <Text fontSize="md" fontWeight="medium" color="black">
                    {shortenText(chat?.question, 20) || `Chat ${index + 1}`}
                    {/* {chat.conversation.response || `Chat ${index + 1}`} */}
                  </Text>
                </Box>
              ))}
            </Flex>
          ) : (
            "Not able to fetch the data"
          )}
        </SidebarItem>
      </VStack>
    
      <Box
        bg="#ffffff"
        color="#000000"
        p={8}
        width="100%"
        maxW="800px"
        mx="auto"
      >
        <Box overflowY={"scroll"} height={"500px"} className="hideScrollbar">
          {messageHistory &&
            messageHistory.length > 0 &&
            messageHistory.map((message, index) => (
              <MessageHandler key={index} message={message} sendMessage={sendMessage} />
            ))}
            {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Box>
        {/* {isLoading && (
        <Flex
position={"absolute"}
marginX={"auto"}
right={"0"}
          justify="center"
          mt={4}
          
        >
          <Spinner size="md" color="#FF8502" />
        </Flex>
      )} */}
        <Box mt={8} width="100%">
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h="80px"
            align="center"
            justify="center"
          >
            <Input
              placeholder="Type your response here..."
              size="lg"
              bg="white"
              pr="4.5rem"

              border="none"
              value={inputValue}
              h="60px"
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              focusBorderColor="transparent"
              _focus={{ boxShadow: "none" }}
            />
            <Image
              w="40px"
              h="40px"
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_button"
              onClick={sendMessage}
            />
          </Flex>
        </Box>
        
      </Box>
      
    </Flex>
  );
};


const MessageHandler = ({ message, sendMessage }) => {
    const COMPONENT_MAP = {
      Query,
      GPT,
      Sources,
      FollowUp,
    };
  
    const Component = COMPONENT_MAP[message.type];
  
    return Component ? (
      <Component content={message.content} sendMessage={sendMessage} />
    ) : null;
  };

  const GPT = ({ content }) => (
    <VStack align="stretch" py={{ base: "0px", sm: "14px" }} spacing={4}>
      <Text
        fontWeight="normal"
        display={"flex"}
        alignItems={"center"}
        gap={"10px"}
        color={"#C01F27"}
        justifyContent={"flex-start"}
        fontSize="12px"
      >
          {/* <Flex flexDirection={"column"}>
          <Text fontSize={"12px"} color={"#C01F27"}>Venture Box AI</Text>
          </Flex> */}
          
        <Image src={roundedLogo} width={"30px"} fontSize={"12px"} color={"#C01F27"} />
        Venture Box AI
      </Text>
      <motion.Box 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
      ml={"45px"}>
        <ReactMarkdown
          className="prose contentBot w-full break-words prose-p:leading-relaxed"
          remarkPlugins={[remarkGfm]}
          components={{
            a: ({ node, ...props }) => (
              <a
                {...props}
                style={{
                  color: "blue",
                  fontSize: "14px",
                  fontWeight: "semibold",
                  marginTop: "8px",
                  marginLeft: "25px",
                }}
                className=""
              />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </motion.Box>
    </VStack>
  );
  
  const Query = ({ content }) => (
    <Box
      bg="#FFFFFF"
      mt={4}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={"16px"}
    >
      <Image
      src={userProfile}
        // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdyTh5ljvubR6s3LeERqK8DHldWwD3DcwBLw&s"
        border={"2px solid #000"}
        borderRadius={"40px"}
        width={"30px"}
        p={0}
        height={"30px"}
      />
          <Flex flexDirection={"column"}>
          <Text fontSize={"12px"} color={"#F68821"}>You</Text>
      <Text fontSize="14px" color={"#333333"}   fontWeight="bold">
        {content ? content : "Not able to fetch question"}
      </Text>
          </Flex>
    </Box>
  );
  
  const Sources = ({ content }) => (
    <Box
      mb={{ base: 0, sm: 8 }}
      mt={4}
      bg="#FFFFFF"
    >
      <Heading
        as="h4"
        size="md"
        fontWeight={400}
        color={"##333333"}
        mb={1}
        mt={1}
      >
        Sources:
      </Heading>
      <Flex
        spacing={4}
        gap={2}
        overflowX={"scroll"}
        css={css`
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
        `}
      >
        {content?.map((source, index) => (
          <Link key={index} href={source.link} isExternal>
            <Box
              p={2}
              bg="#FEF4E9"
              color="#000000"
              minW={"221px"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              borderRadius="md"
              minH={"56px"}
              mt={2}
            >
              <Text color={"#333333"} fontSize={"12px"} lineHeight={"18px"}>
                {source.title.slice(0, 30)}
              </Text>
              <Text color={"#C01F27"} fontSize="10px">
                {new URL(source.link).hostname}
              </Text>
            </Box>
          </Link>
        ))}
      </Flex>
    </Box>
  );
  
  const FollowUp = ({ content, sendMessage }) => {
    const [followUp, setFollowUp] = useState([]);
    const messagesEndRef = useRef(null);
  
    useEffect(() => {
      if (content[0] === "{" && content[content.length - 1] === "}") {
        try {
          const parsed = JSON.parse(content);
          setFollowUp(parsed.follow_up || []);
        } catch (error) {
          console.log("error parsing json", error);
        }
      }
    }, [content]);
  
    useEffect(() => {
      scrollToBottom();
    }, [followUp]);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    const handleFollowUpClick = (text, e) => {
      e.preventDefault();
      sendMessage(text);
    };
  
    if (followUp.length > 0)
      return (
        <Box my={4} bg="#FFFFFF">
          <Heading fontSize="16px" lineHeight={"24px"} pb={"12px"} color={"#F68821"} fontWeight={400}>
            Related questions:
          </Heading>
          {followUp.map((text, index) => (
            <Link
              key={index}
              href="#"
              onClick={(e) => handleFollowUpClick(text, e)}
              display="block"
              p={"8px 0px"}
              borderTop={"1px solid #FEE6D4"}
            >
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text p={"6px 0px"} fontSize={"14px"}>{text}</Text>
                <Image src={plus_icon} h={"30px"} alt="" />
              </Flex>
            </Link>
          ))}
          <div ref={messagesEndRef} />
        </Box>
      );
    else return <div></div>;
  };

const Message = ({ content, type }) => (
  <Box
    alignSelf={type === "Query" ? "flex-start" : "flex-end"}
    p={2}
    borderRadius="md"
    mb={2}
    maxWidth="70%"
  >
    <Text fontWeight={type === "Query" ? "bold" : "normal"}>
      {type === "Query" ? "You" : "Master Coach"}
    </Text>
    <Text>{content}</Text>
  </Box>
);

const SourcesMessage = ({ sources }) => (
  <Box
    alignSelf="flex-end"
    p={2}
    borderRadius="md"
    mb={2}
    maxWidth="70%"
    bg="gray.100"
  >
    <Text fontWeight="normal">Master Coach - Sources:</Text>
    <Box pl={4}>
      {sources.map((source, index) => (
        <Box key={index} mt={2}>
          <Link href={source.link} isExternal color="blue.500">
            {source.title}
          </Link>
        </Box>
      ))}
    </Box>
  </Box>
);

const FollowUpMessage = ({ content }) => {
  let parsedContent = [];
  try {
    parsedContent = JSON.parse(content).follow_up || [];
  } catch (error) {
    console.error("Error parsing follow-up content:", error);
  }

  return (
    <Box
      alignSelf="flex-end"
      p={2}
      borderRadius="md"
      mb={2}
      maxWidth="70%"
      bg="gray.100"
    >
      <Text fontWeight="normal">Master Coach - Follow-Up Questions:</Text>
      <Box pl={4}>
        {parsedContent.map((question, index) => (
          <Text key={index} mt={2}>
            {question}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default HrCoach;
