import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  Collapse,
  Link,
  useToast,
  Button,
  CloseButton,
} from "@chakra-ui/react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Icon, createIcon } from "@chakra-ui/icons";

import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/genie_logo_full.png";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import home_icon from "../../assets/home_icon.png";
import start_new from "../../assets/start_new.png";
import useApiClient from "../../services/useApiClient";
import { shortenText } from "../../services/common";
import userProfile from "../../assets/You.png";
import FeedbackComponent from "../Sales/FeedbackComponent/FeedbackComponent";
import HeyButton from "../HeyButton/HeyButton";
import ConversationRating from "../ConversationRating/ConversationRating";

// Custom ThumbUpIcon
const ThumbUpIcon = createIcon({
  displayName: "ThumbUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"
    />
  ),
});

// Custom ThumbDownIcon
const ThumbDownIcon = createIcon({
  displayName: "ThumbDownIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4H6.56c-.71 0-1.37.37-1.73.97L2.17 11.12z"
    />
  ),
});

const SidebarItem = ({ label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
      <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const FeedbackOption = ({ option, isSelected, onClick }) => (
  <Box
    as="button"
    px={4}
    py={2}
    borderRadius="full"
    bg={isSelected ? "#ff8400" : "gray.100"}
    color={isSelected ? "white" : "black"}
    _hover={{ bg: isSelected ? "#ff8400" : "gray.200" }}
    onClick={onClick}
    transition="all 0.2s"
  >
    <Text fontSize="12px">{option}</Text>
  </Box>
);

const MarketingComponent = ({
  breadcrumbPaths,
  chatHistoryAPI,
  selectedChatIdAPI,
  newChat,
  sendMessagee,
  title,
}) => {
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const toast = useToast();
  // FOr Hey button
  const [showHeyButton, setShowHeyButton] = useState(true);
  // For Feedback
  const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [feedbackType, setFeedbackType] = useState(null);
  const [showRating, setShowRating] = useState(false);


  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${chatHistoryAPI}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        scrollToBottom();
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, [chatId]);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    setChatId(chatId);
    navigate(`${selectedChatIdAPI}/${chatId}`);
    scrollToBottom();
  };

  const getNewChatId = async (question) => {
    try {
      const response = await api.post(`${newChat}`, {
        question,
      });
      if (response) {
        const data = response.data;
        console.log("New chat ID:", data.chat_id);
        setChatId(data.chat_id);
        console.log("Chat ID set:", chatId);
        return data.chat_id;
      } else {
        console.error("Error getting new chat ID:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error getting new chat ID:", error);
      return null;
    }
  };

  const sendMessage = async (message, chatId) => {
    try {
      const response = await api.post(`${sendMessagee}`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        return data;
      } else {
        console.error("Error sending message:", response.statusText);
        return { error: "Failed to send message" };
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return { error: "Failed to send message" };
    }
  };

  const handleRatingSubmission = async (chatId, rating) => {
    try {
      const response = await api.post(`${selectedChatIdAPI}/conversation-rating`, {
        chat_id: chatId,
        conversation_rating: rating
      });
      return response;
    } catch (error) {
      console.error("Error submitting rating:", error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      setChatMessages((prev) => [
        ...prev,
        { type: "user", content: inputValue },
      ]);
      const userMessage = inputValue;
      setInputValue("");

      setIsLoading(true);

      let currentChatId = chatId;

      try {
        if (!currentChatId) {
          currentChatId = await getNewChatId(userMessage);
          setChatId(currentChatId);
          console.log("New chat ID:", currentChatId);
        }

        const response = await sendMessage(userMessage, currentChatId);
        console.log("Response from sendMessage:", response);

        let aiResponse = "No response received";
        if (response && response.response) {
          aiResponse = response.response;
        } else if (response && response.error) {
          aiResponse = `Error: ${response.error}`;
        }

        setChatMessages((prev) => [
          ...prev,
          { type: "ai", content: aiResponse, messageid: response.aiMessageId },
        ]);
        // Inside handleSendMessage, just before setIsLoading(false):
if ((chatMessages.length + 2) % 10 === 0) {
  setShowRating(true);
}
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setChatMessages((prev) => [
          ...prev,
          {
            type: "ai",
            content: "Sorry, an error occurred while processing your message.",
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImpression = async (messageId, impression) => {
    setCurrentMessageId(messageId);
    setFeedbackType(impression ? 'positive' : 'negative');
    setShowFeedbackOptions(true);
    setSelectedReasons([]);
  };

  const handleReasonToggle = (reason) => {
    setSelectedReasons(prev => 
      prev.includes(reason)
        ? prev.filter(r => r !== reason)
        : [...prev, reason]
    );
  };

  const handleCloseFeedback = async () => {
    try {
      const response = await api.post("/marketing/playbook/feedback", {
        chat_id: chatId,
        message_id: currentMessageId,
        isLiked: feedbackType === 'positive',
        reasons: [], // Empty array as requested
      });

      if (response.status === 200) {
        toast({
          title: "Feedback submitted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setShowFeedbackOptions(false);
        setSelectedReasons([]);
        setChatMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageid === currentMessageId ? { ...msg, impression: feedbackType === 'positive' } : msg
          )
        );
      } else {
        throw new Error("Failed to submit feedback");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Failed to submit feedback",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const submitDetailedFeedback = async () => {
    try {
      const response = await api.post("/marketing/playbook/feedback", {
        chat_id: chatId,
        message_id: currentMessageId,
        isLiked: feedbackType === 'positive',
        reasons: selectedReasons,
      });

      if (response.status === 200) {
        toast({
          title: "Feedback submitted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setShowFeedbackOptions(false);
        setSelectedReasons([]);
        setChatMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageid === currentMessageId ? { ...msg, impression: feedbackType === 'positive' } : msg
          )
        );
      } else {
        throw new Error("Failed to submit detailed feedback");
      }
    } catch (error) {
      console.error("Error submitting detailed feedback:", error);
      toast({
        title: "Failed to submit feedback",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleFeedbackSubmission = async (feedbackData) => {
    console.log(selectedChatIdAPI + "/feedback");
        try {
          const response = await api.post(`${selectedChatIdAPI}/feedback`, feedbackData);
          if (response.status === 200) {
            setChatMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.id === feedbackData.message_id ? { ...msg, impression: feedbackData.isLiked } : msg
              )
            );
          } else {
            throw new Error("Failed to submit feedback");
          }
        } catch (error) {
          console.error("Error submitting feedback:", error);
          throw error;
        }
      };

  return (
    <Flex minH="100vh" bg="white">
      <Flex
        gap={"12px"}
        height={{ base: "", sm: "100vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 5, sm: 16 }}
      >
        <VStack align="stretch" spacing={4}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            Marketing {title}
          </Text>
        </VStack>

        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
          px={4}
        >
       {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              p={2}
              className="hideScrollbar"
              borderRadius="md"
              mb={2}
              maxWidth={{ base: "80%", lg: "90%" }}
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"36px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
            {/* {console.log(message.messageid,"marketing component")} */}
              {message.type === "ai" && (
            <FeedbackComponent
              messageId={message.messageid}
              chatId={chatId}
              onSubmitFeedback={handleFeedbackSubmission}
            />
          )}
              {/* {showFeedbackOptions && message.type === "ai" && message.messageid === currentMessageId && (
                <Box
                  bg="white"
                  p={4}
                  mt={4}
                  borderRadius="md"
                  border="1px solid #e5e5e5"
                  zIndex={1000}
                  position="relative"
                >
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={handleCloseFeedback}
                  />
                  <Text fontWeight="semibold" fontSize="15px" mb={2}>
                    Tell us more:
                  </Text>
                  <Flex flexWrap="wrap" gap={2} justify="start">
                    {(feedbackType === 'positive' ? positiveFeedbackOptions : negativeFeedbackOptions).map((option) => (
                      <FeedbackOption
                        key={option}
                        option={option}
                        isSelected={selectedReasons.includes(option)}
                        onClick={() => handleReasonToggle(option)}
                      />
                    ))}
                  </Flex>
                  <Flex justify="end" mt={4}>
                    <Button onClick={submitDetailedFeedback}>Submit Feedback</Button>
                  </Flex>
                </Box>
              )} */}
            </Box>
          ))}
          {showHeyButton && chatMessages.length === 0 && (
    <HeyButton 
      isVisible={showHeyButton && chatMessages.length === 0}
      onChatStart={async () => {
        try {
          const currentChatId = await getNewChatId("Hey");
          setChatId(currentChatId);
          
          const response = await sendMessage("Hey", currentChatId);
          
          if (response && !response.error) {
            setChatMessages([
              {
                type: "user",
                messageid: response.userMessageId,
                content: "Hey",
              },
              {
                type: "ai",
                messageid: response.aiMessageId,
                content: response.response,
              },
            ]);
          }
          setShowHeyButton(false);
        } catch (error) {
          console.error("Error in Hey button:", error);
          toast({
            title: "Error",
            description: "Failed to start conversation. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }}
      customStyle="px-6 py-3"
    />
  )}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
        </Flex>
        <ConversationRating
          isOpen={showRating}
          onClose={() => setShowRating(false)}
          onSubmitRating={handleRatingSubmission}
          chatId={chatId}
        />
{showRating ? '' : (


        <Box
          position="sticky"
          bottom={0}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          mx={"auto"}
          bg="white"
          p={4}
        >
          <Flex
            border={"1px solid #D9D9D9"}
            borderRadius={"16px"}
            h={"70px"}
            align={"center"}
            justifyContent={"center"}
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border={"none"}
              h={"40px"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor={"transparent"}
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
          </Flex>
        </Box>
      )}
      </Flex>
    </Flex>
  );
};

export default MarketingComponent;