import React from "react";
import { Flex, Link, LinkBox, Text } from "@chakra-ui/react";
import FooterLinkedin from '../../assets/linkedinFooter.svg'
import FooterTwitter from '../../assets/twitterFooter.svg'
import FooterFacebook from '../../assets/facebookFooter.svg'
const Footer = () => {
  return (
    <Flex
    position={"absolute"}
    left={0}
    right={0}
    bottom={0}
      display={"flex"}
    //   bottom={{base:"-800px",lg:"0"}}
      flexDirection={"column"}
      gap={"10px"}
      justifyContent={"center"}
      alignItems={"center"}
      py={{base:20,lg:4}}
      
      backgroundColor={{base:"#fff",lg:"#fff"}}
    >
      <Flex display={"flex"} justifyContent={"center"}  alignItems={"center"}>
        Built by &nbsp;<Text color="blue">@VentureBoxAI</Text> &nbsp;
        &nbsp;
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          id="heart"
        >
          <path
            fill="#f05542"
            d="M5.301 3.002c-.889-.047-1.759.247-2.404.893-1.29 1.292-1.175 3.49.26 4.926l.515.515L8.332 14l4.659-4.664.515-.515c1.435-1.437 1.55-3.634.26-4.926-1.29-1.292-3.483-1.175-4.918.262l-.516.517-.517-.517C7.098 3.438 6.19 3.049 5.3 3.002z"
          ></path>
        </svg>
      </Flex>
      {/* <Flex display={"flex"} gap={"6px"} justifyContent={"center"}  alignItems={"center"}>
        <a href="#">
          <img src={FooterLinkedin} width={"24px"} alt="linkedin" />
        </a>
        <a href="#">
          <img src={FooterTwitter} width={"24px"} alt="twitter" />
          </a>
        <a href="#">
            <img src={FooterFacebook} width={"24px"} alt="facebook" />
        </a>
        </Flex> */}
        <Flex flexDirection={{base:"column",lg:"row"}} display={"flex"} px={{base:1,lg:0}} justifyContent={"center"}  alignItems={"center"}>
       {/* <Flex></Flex> */}
        <Text>Copyright VentureBox AI All Rights Reserved
         </Text>
        <Flex>
        &nbsp; | &nbsp;
         <Link href="/terms" cursor={"pointer"} color={"blue.400"}> Terms of Use</Link> &nbsp; | &nbsp; <Link href="/privacy-policy" cursor={"pointer"}  color={"blue.400"}>Privacy Policy</Link>
        </Flex>
        </Flex>
    </Flex>
  );
};

export default Footer;
