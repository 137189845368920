import React from 'react'
import Idea from '../IdeaGenerator/Idea'

const IdeaGenerator = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Strategy", href: "/strategy" },
    { label: "Idea Generator", href: `/strategy/idea-generator` },
  ];
  return (
    <div>
      <Idea 
      breadcrumbPaths={breadcrumbPaths}
      selectedChatIdAPI={"/strategy/idea_generator"}
      chatHistoryAPI={"/strategy/idea_generator/list"}
      selectedChatId={"/strategy/idea-generator"}
      newChat={"/strategy/idea_generator/new_chat"}
      sendMessagee={"/strategy/idea_generator/chat"}
      title={"Generator"}
       />
    </div>
  )
}

export default IdeaGenerator
