import React from "react";
import ChatpagePlaybook from "../ChatpagePlaybook/ChatpagePlaybook";

const ChatPafeStrategy = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Strategy", href: "/strategy" },
    { label: "Playbook", href: `/strategy/playbook/` },
  ];
  return (
    <div>
      <ChatpagePlaybook
        breadcrumbPaths={breadcrumbPaths}
           chatHistoryAPIfeedback="/strategy/playbook/feedback"
        chatHistoryAPIendpoint="/strategy/playbook/list"
        chatMessageendpoint="/strategy/playbook/"
        chatendpoint="/strategy/playbook/chat"
        title="Playbook"
        selectChat="/strategy/playbook/"
      />
    </div>
  );
};

export default ChatPafeStrategy;
