import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  Collapse,
  Link,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/geneiLogoOrange.svg";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import home_icon from "../../assets/home_icon.png";
import start_new from "../../assets/start_new.png";
import useApiClient from "../../services/useApiClient";
import { shortenText } from "../../services/common";
import userProfile from "../../assets/You.png";
import FeedbackHistory from "../FeedbackHistory/FeedbackHistory";

const UserPersonaChat = () => {
  const { chatId } = useParams();
  const { api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();

  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Marketing", href: "/marketing" },
    { label: "User Persona Creator", href: `/marketing/marketing-persona-creator` },
  ];

  useEffect(() => {
    fetchChatHistory();
    if (chatId) {
      fetchChatMessages();
    }
  }, [chatId]);

  const fetchChatHistory = async () => {
    try {
      const response = await api.get("/marketing/persona_creator/list");
      if (response) {
        setChatHistory(response.data);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };

  const fetchChatMessages = async () => {
    try {
      const response = await api.get(`/marketing/persona_creator/${chatId}`);
      if (response && response.data && response.data.length > 0) {
        const chatData = response.data[0].messages;
        setChatMessages(chatData);
      } else {
        console.log("Error fetching chat messages or no messages found");
      }
    } catch (error) {
      console.log("Error fetching chat messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      const userMessage = inputValue;
      setInputValue("");
      setIsLoading(true);

      try {
        const response = await api.post("/marketing/persona_creator/chat", {
          chat_id: chatId,
          message: userMessage,
        });
        if (response && response.data) {
          setChatMessages((prev) => [
            ...prev,
            { type: "user", content: userMessage },
            { type: "ai", content: response.data.response },
          ]);
        }
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setChatMessages((prev) => [
          ...prev,
          {
            type: "ai",
            content: "Sorry, an error occurred while processing your message.",
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFeedbackSubmit = async (apiEndpoint, feedbackData) => {
    try {
      await api.post(apiEndpoint, {
        chat_id: chatId,
        ...feedbackData
      });
      toast({
        title: "Feedback submitted",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      // Update the chat messages with the new feedback
      setChatMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.messageid === feedbackData.message_id
            ? { ...msg, isLiked: feedbackData.isLiked, reasons: feedbackData.reasons }
            : msg
        )
      );
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Failed to submit feedback",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH="100vh" bg="white">
    
      {/* ... (keep existing sidebar code) */}

      <Flex
        gap={"12px"}
        height={{ base: "", sm: "95vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 5, sm: 16 }}
      >
        <VStack align="stretch" spacing={4}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            User Persona Creator
          </Text>
        </VStack>

        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
          mb={4}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
        >
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              p={2}
              borderRadius="md"
              mb={2}
              maxWidth="70%"
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box AI
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"36px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
              {message.type === "ai" && (
                <FeedbackHistory
                  messageId={message.messageid}
                  onFeedbackSubmit={handleFeedbackSubmit}
                  apiEndpoint="/marketing/persona_creator/feedback"
                  initialFeedback={{
                    isLiked: message.isLiked,
                    reasons: message.reasons || []
                  }}
                />
              )}
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Flex>

        <Box
          mb={8}
          pos={"relative"}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
        >
          <Flex
            border={"1px solid #D9D9D9"}
            borderRadius={"16px"}
            h={"70px"}
            align={"center"}
            justifyContent={"center"}
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border={"none"}
              h={"40px"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor={"transparent"}
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default UserPersonaChat;