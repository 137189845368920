import React from 'react';
import {
  Box,
  Flex,
  Text,
  HStack,
  IconButton,
  Slide,
  useToast
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';


const ConversationRating = ({ 
  isOpen, 
  onClose, 
  onSubmitRating, 
  chatId 
}) => {
  const [rating, setRating] = React.useState(0);
  const toast = useToast();

  const handleRating = async (selectedRating) => {
    setRating(selectedRating);
    
    try {
      await onSubmitRating(chatId, selectedRating);
      
      toast({
        title: "Thank you for providing a rating!",
        description: "Your feedback helps us improve.",
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Failed to submit rating",
        description: "Please try again later.",
        status: "error",
        duration: 2000,
      });
    } finally {
      setRating(0);
      onClose();
    }
  };

  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{ zIndex: 10 }}
    >
      <Box 
        p={4} 
        bg="white" 
        shadow="md" 
        borderTopRadius="md"
        maxW="md"
        mx="auto"
      >
        <Text mb={2} textAlign="center" fontWeight="medium">
          How would you rate this conversation?
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <HStack spacing={2}>
            {[1, 2, 3, 4, 5].map((star) => (
              <IconButton
                key={star}
                icon={
                  <StarIcon
                    fill={star <= rating ? "#ff8400" : "none"}
                    color={star <= rating ? "#ff8400" : "#cbd5e0"}
                  />
                }
                onClick={() => handleRating(star)}
                aria-label={`Rate ${star} stars`}
                variant="ghost"
                _hover={{
                  bg: "gray.100"
                }}
              />
            ))}
          </HStack>
        </Flex>
      </Box>
    </Slide>
  );
};

export default ConversationRating;