import { Button, Flex, Spinner, useToast } from "@chakra-ui/react";
import React, { useState } from "react";

const HeyButton = ({
  onChatStart,
  isVisible = true,
  customStyle = {},
  buttonText = "Hey 👋",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleHeyClick = async () => {
    setIsLoading(true);
    try {
      await onChatStart();
    } catch (error) {
      console.error("Error starting conversation:", error);
      toast({
        title: "Error occurred",
        description: "Failed to start conversation. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <Flex justifyContent={"center"} alignItems={"flex-end"} height={"90%"}>
      <Button
        onClick={handleHeyClick}
        _hover={{ bg: `${isLoading ? "#fff" :"#FF8502"}`, color: "white" }}
        border={`1px solid ${isLoading ? "#fff" :"#FF8502"}`}
        backgroundColor={"#FFF"}
        className={`hover:bg-[#FF8502] hover:text-white bg-white border border-[#FF8502] ${customStyle}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <Flex justify="center" mt={4}>
            <Spinner size="md" color="#FF8502" />
          </Flex>
        ) : (
          buttonText
        )}
      </Button>
    </Flex>
  );
};

export default HeyButton;
