import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  useToast,
  Collapse,
  Link,
  createIcon,
  Button,
  Icon,
  Tooltip,
  CloseButton,
} from "@chakra-ui/react";

import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/geneiLogoOrange.svg";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import useApiClient from "../../services/useApiClient";
// import { shortenText } from "../../services/common";
import userProfile from "../../assets/You.png";

const ThumbUpIcon = createIcon({
  displayName: "ThumbUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"
    />
  ),
});

// Custom ThumbDownIcon
const ThumbDownIcon = createIcon({
  displayName: "ThumbDownIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4H6.56c-.71 0-1.37.37-1.73.97L2.17 11.12z"
    />
  ),
});

const ChatpagePlaybook = ({
  breadcrumbPaths,
  chatHistoryAPIendpoint,
  chatMessageendpoint,
  chatendpoint,
  selectChat,
  title,
  chatHistoryAPIfeedback
}) => {
  const { chatId } = useParams();
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
 // New state variables for feedback functionality
 const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);
 const [currentMessageId, setCurrentMessageId] = useState(null);
 const [selectedReasons, setSelectedReasons] = useState([]);
 const [feedbackType, setFeedbackType] = useState(null);
const toast = useToast();
 const negativeFeedbackOptions = [
   "Shouldn't have used Memory",
   "Don't like the style",
   "Not factually correct",
 ];

 const positiveFeedbackOptions = [
   "Very helpful",
   "Well explained",
   "Accurate information",
 ];
 
  useEffect(() => {
    fetchChatHistory();
    if (chatId) {
      fetchChatMessages();
    }
  }, [chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatId]);

    // New functions for feedback system
    const handleImpression = async (messageId, impression) => {
      setCurrentMessageId(messageId);
      setFeedbackType(impression ? 'positive' : 'negative');
      setShowFeedbackOptions(true);
      setSelectedReasons([]);
  
      // Update the message's impression immediately
      setChatMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.messageid === messageId ? { ...msg, isLiked: impression } : msg
        )
      );
      try {
        await api.post(`${chatHistoryAPIfeedback}`, {
          chat_id: chatId,
          message_id: messageId,
          isLiked: impression,
          reasons: []
        });
      } catch (error) {
        console.error("Error submitting impression:", error);
        toast({
          title: "Failed to submit feedback",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };  
  

    const handleCloseFeedback = async () => {
      try {
        await api.post(`${chatHistoryAPIfeedback}`, {
          chat_id: chatId,
          message_id: currentMessageId,
          isLiked: feedbackType === 'positive',
          reasons: [], // Empty array as requested
        });
  
        toast({
          title: "Feedback submitted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
  
        setShowFeedbackOptions(false);
        setSelectedReasons([]);
        setChatMessages(prevMessages =>
          prevMessages.map(msg =>
            msg.messageid === currentMessageId
              ? { ...msg, isLiked: feedbackType === 'positive', reasons: [] }
              : msg
          )
        );
      } catch (error) {
        console.error("Error submitting feedback:", error);
        toast({
          title: "Failed to submit feedback",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };


    const handleReasonToggle = (reason) => {
      setSelectedReasons(prev => 
        prev.includes(reason)
          ? prev.filter(r => r !== reason)
          : [...prev, reason]
      );
    };
    const submitDetailedFeedback = async () => {
      try {
        const response = await api.post(`${chatHistoryAPIfeedback}`, {
          chat_id: chatId,
          message_id: currentMessageId,
          isLiked: feedbackType === 'positive',
          reasons: selectedReasons,
        });
  
        if (response.status === 200) {
          toast({
            title: "Feedback submitted",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setShowFeedbackOptions(false);
          setChatMessages(prevMessages =>
            prevMessages.map(msg =>
              msg.messageid === currentMessageId
                ? { ...msg, isLiked: feedbackType === 'positive', reasons: selectedReasons }
                : msg
            )
          );
        } else {
          throw new Error("Failed to submit detailed feedback");
        }
      } catch (error) {
        console.error("Error submitting detailed feedback:", error);
        toast({
          title: "Failed to submit feedback",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };
  

    const FeedbackOption = ({ option, isSelected, onClick }) => (
      <Box
        as="button"
        px={4}
        py={2}
        borderRadius="full"
        bg={isSelected ? "#ff8400" : "gray.100"}
        color={isSelected ? "white" : "black"}
        _hover={{ bg: isSelected ? "#ff8400" : "gray.200" }}
        onClick={onClick}
        transition="all 0.2s"
      >
        <Text fontSize="12px">{option}</Text>
      </Box>
    );

  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${chatHistoryAPIendpoint}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const fetchChatMessages = async () => {
    try {
      const response = await api.get(`${chatMessageendpoint}${chatId}`);
      if (response && response.data && response.data.length > 0) {
        const chatData = response.data[0].messages;
        setChatMessages(chatData);
      } else {
        console.log("Error fetching chat messages or no messages found");
      }
    } catch (error) {
      console.log("Error fetching chat messages:", error);
    }
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const sendMessage = async (message) => {
    try {
      const response = await api.post(`${chatendpoint}`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        setChatMessages((prev) => [
          ...prev,
          { type: "user", content: message, messageid: data.userMessageId },
          { type: "ai", content: data.response, messageid: data.aiMessageId },
        ]);
        return data;
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      const userMessage = inputValue;
      setInputValue("");
      setIsLoading(true);

      try {
        await sendMessage(userMessage);
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        toast({
          title: "Error",
          description: "Failed to send message. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const handleSelectChat = (chatId) => {
    // setSelectedChatId(chatId);
    // setChatId(chatId);
    console.log(chatId);
    navigate(`${selectChat}${chatId}`);
    // window.location.href = `/strategy/playbook/${chatId}`;
    // Here you might want to fetch the messages for the selected chat
    // and update the chatMessages state
  };

  // Include other necessary functions like sendMessage, etc.

  return (
    <Flex minH="100vh" bg="white">
      {/* Sidebar */}

      {/* Similar to Gtm component */}

      <Flex
        gap={"12px"}
        height={{ base: "", sm: "101vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 3, sm: 16 }}
      >
        <VStack align="stretch" spacing={4} p={5}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            {title} Chat
          </Text>
        </VStack>

        {/* Chat messages */}
        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
          mb={4}
          // height={"50%"}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
        >
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              p={2}
              borderRadius="md"
              mb={2}
              ref={chatContainerRef}
              maxWidth={{ base: "80%", lg: "95%" }}
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box AI
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"33px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
             {message.type === "ai" && (
              <Flex justifyContent="flex-end" mt={2}>
                <Tooltip label={message.isLiked === true && message.reasons ? message.reasons.join(", ") : "Thumbs up"}>
                  <IconButton
                    icon={<ThumbUpIcon />}
                    aria-label="Thumbs up"
                    size="sm"
                    mr={2}
                    onClick={() => handleImpression(message.messageid, true)}
                    colorScheme={message.isLiked === true ? "green" : "gray"}
                  />
                </Tooltip>
                <Tooltip label={message.isLiked === false && message.reasons ? message.reasons.join(", ") : "Thumbs down"}>
                  <IconButton
                    icon={<ThumbDownIcon />}
                    aria-label="Thumbs down"
                    size="sm"
                    onClick={() => handleImpression(message.messageid, false)}
                    colorScheme={message.isLiked === false ? "red" : "gray"}
                  />
                </Tooltip>
              </Flex>
            )}
            {showFeedbackOptions && message.type === "ai" && message.messageid === currentMessageId && (
              <Box
                bg="white"
                p={4}
                mt={4}
                borderRadius="md"
                border="1px solid #e5e5e5"
                zIndex={1000}
                position="relative"
              >
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={handleCloseFeedback}
                />
                <Text fontWeight="semibold" fontSize="15px" mb={2}>
                  Tell us more:
                </Text>
                <Flex flexWrap="wrap" gap={2} justify="start">
                  {(feedbackType === 'positive' ? positiveFeedbackOptions : negativeFeedbackOptions).map((option) => (
                    <FeedbackOption
                      key={option}
                      option={option}
                      isSelected={selectedReasons.includes(option)}
                      onClick={() => handleReasonToggle(option)}
                    />
                  ))}
                </Flex>
                <Flex justify="end" mt={4}>
                  <Button onClick={submitDetailedFeedback}>Submit Feedback</Button>
                </Flex>
              </Box>
            )}
                
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Flex>
        <Box
          position="sticky"
          bottom={0}
          width="100%"
          borderRadius={"15px"}
          bg={{ base: "white", lg: "white" }}
          p={{ base: 2, lg: 4 }}
        >
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h="70px"
            align="center"
            justify="center"
            width={{ base: "100%", sm: "75%", lg: "65%" }}
            mx="auto"
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border="none"
              h="40px"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor="transparent"
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
            {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChatpagePlaybook;
