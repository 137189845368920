import React from "react";
import MarketingComponent from "./MarketingComponent";

const UserPersonaCreator = () => {

  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Marketing", href: "/marketing" },
    { label: "User Persona Creator", href: `/marketing/marketing-persona-creator` },
  ];

  return (
    <div>
      <MarketingComponent
        breadcrumbPaths={breadcrumbPaths}
        selectedChatIdAPI={"/marketing/persona_creator"}
        chatHistoryAPI={"/marketing/persona_creator/list"}
        selectedChatId={"/marketing/persona_creator"}
        newChat={"/marketing/persona_creator/new_chat"}
        sendMessagee={"/marketing/persona_creator/chat"}
        title={"User Persona Creator"}
      />
    </div>
  );
};


export default UserPersonaCreator;
