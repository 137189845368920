import React from "react";
import SalesComponent from "./SalesComponent";

const SalesConsultant = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Sales", href: "/sales" },
    { label: "Sales Consultant", href: `/sales/sales-consultant` },
  ];
  return (
    <div>
      <SalesComponent
        breadcrumbPaths={breadcrumbPaths}
        chatHistoryAPI={"/sales/sales_consultant/list"}
        selectedChatId={"/sales/sales_consultant"}
        selectedChatIdAPI={"/sales/sales_consultant"}
        newChat={"sales/sales_consultant/new_chat"}
        sendMessagee={"/sales/sales_consultant/chat"}
        title={"Consultant"}
      />
    </div>
  );
};

export default SalesConsultant;
