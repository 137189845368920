import React from 'react'
import Idea from '../IdeaGenerator/Idea'

const ideaEnhancer = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Strategy", href: "/strategy" },
    { label: "Idea Enhancer", href: `/strategy/idea-enhancer` },
  ];

  return (
    <div>
  <Idea 
      breadcrumbPaths={breadcrumbPaths}
      chatHistoryAPI={"/strategy/idea_enhancer/list"}
      selectedChatIdAPI={"/strategy/idea_enhancer"}
      selectedChatId={"/strategy/idea-enhancer"}
      newChat={"/strategy/idea_enhancer/new_chat"}
      sendMessagee={"/strategy/idea_enhancer/chat"}
      title={"Enhancer"}
       />      
    </div>
  )
}

export default ideaEnhancer